:root {
  --font-base: "DM Sans", sans-serif;

  --primary-color: #edf2f8;
  --secondary-color: #313bac;
  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

.app__navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 2;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 90px;
    height: 45px;

    @media screen and (min-width: 2000px) {
      width: 180px;
      height: 20px;
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  position: relative;

  li {
    margin: 0 1rem;
    cursor: pointer;
    flex-direction: column;

    div {
      width: 5px;
      height: 5px;
      background: transparent;
      border-radius: 50%;

      margin-bottom: 5px;
    }

    a {
      color: var(--gray-color);
      text-decoration: none;
      flex-direction: column;
      text-transform: uppercase;
      font-weight: 500;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--secondary-color);
      }
    }

    &:hover {
      div {
        background: var(--secondary-color);
      }
    }
  }
  @media screen and (max-width: 900px) {
    display: none;
  }
}

.app__navbar-cv {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  height: 50px;
  width: 135px;
  background-color: #fff;
  color: #000;
  font-weight: 800;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0.5rem;
  color: var(--gray-color);
  display: flex;
  margin: 0;
  box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

  &:hover {
    background: var(--secondary-color);
    color: #fff;
    box-shadow: 0 0 20px rgba(168, 168, 168, 0.9);
  }

  @media screen and (max-width: 900px) {
    display: none;
  }

  .app__navbar-cv-btn {
    color: var(--gray-color);
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      width: 35px;
      height: 35px;
    }

    &:hover {
      color: #fff;
    }
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--secondary-color);

  svg {
    width: 70%;
    height: 70%;
    color: var(--white-color);
    position: absolute;

    cursor: pointer;
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
    padding: 1rem;
    width: 80%;
    height: 100vh;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    background: url(../../assets/bgWhite.png);
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;

    box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

    &:hover {
      box-shadow: 0 0 20px rgba(168, 168, 168, 0.95);
    }

    .app__menu-cv {
      position: absolute;
      top: 400px;
      left: 10px;

      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      height: 50px;
      width: 150px;
      background: #fff;
      color: #000;
      font-weight: 800;
      cursor: pointer;
      transition: all 0.3s ease;
      margin: 0.5rem;
      color: var(--gray-color);
      display: flex;
      justify-content: center;
      align-items: space-between;
      margin: 0;
      box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

      &:hover {
        background: var(--secondary-color);
        color: #fff;
      }

      .app__menu-cv-btn {
        display: flex;
        position: relative;
        justify-content: space-between;
        position: static;

        color: var(--gray-color);
        text-decoration: none;

        &:hover {
          color: #fff;
        }

        .app__menu-icon {
          color: var(--gray-color);
          position: absolute;
          right: 0;
          width: 30px;
          height: 30px;

          &:hover {
            color: #fff;
          }
        }
      }
    }

    @media screen and (max-width: 393px) {
      width: 100%;
    }

    svg {
      width: 35px;
      height: 35px;
      color: var(--secondary-color);
      margin: 0.5rem 1rem;
      align-self: flex-end;
      cursor: pointer;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      li {
        margin: 1rem;
        color: #e4e4e4;

        a {
          color: var(--gray-color);
          text-decoration: none;
          font-size: 1rem;
          cursor: pointer;
          text-transform: uppercase;
          font-weight: 500;

          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--secondary-color);
          }
        }
      }
    }
  }
  @media screen and (min-width: 900px) {
    display: none;
  }
}
