.copyright {
  width: 100%;
  height: 100px;
  // padding: 2rem 0 0;
  padding: 2rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  p {
    text-transform: uppercase;
    color: var(--black-color);
  }
}

@media screen and (max-width: 500px) {
  .copyright {
    padding: 2rem;
  }
}
